import "./App.css";
import { Grid, Paper } from "@material-ui/core";

import Header from "./comopnents/Header";
import Content from "./comopnents/Content";
import TopImage from "./comopnents/TopImage";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url(./asset/images/sea.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "300px",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Header />
      <TopImage />
      <Content />
    </Grid>
  );
}

export default App;
