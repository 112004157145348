import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, Divider } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    //padding: "10px",
  },
  cardTitle: {
    fontSize: "14pt",
    padding: "5px",
  },
});

export default function ImgMediaCard(props) {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={6} sm={6} md={6}>
      <Card className={classes.root} variant="outlined">
        <CardActionArea href={props.href} target="_blank">
          <Typography variant="h6" component="h3" className={classes.cardTitle}>
            {props.title}
          </Typography>
          <Divider />
          <CardMedia
            component="img"
            alt={props.title}
            height="360"
            image={props.image}
            title={props.title}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.description,
                }}
              ></div>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
