import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    //textAlign: "center",
    //color: theme.palette.text.secondary,
  },
  explanatoryText: {
    fontSize: "16pt",
    paddingBottom: "10pt",
  },
}));

export default function TopDescription() {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography className={classes.explanatoryText} variant={"h1"}>
        実験的な自主学習コンテンツや学習記録を配信しております。
      </Typography>
    </Paper>
  );
}
