import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import App from "../App";

const Header = () => {
  return (
    <AppBar position="static" style={{ backgroundColor: "#333333" }}>
      <Toolbar>
        <Typography variant="h6">ufirst.jp</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
