import { Paper } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url(./asset/images/sea.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "300px",
    borderRadius: "0px",
  },
}));

export default function TopImage() {
  const classes = useStyles();
  return <Paper item lg={12} sm={12} md={12} className={classes.image}></Paper>;
}
