import { Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ImgMediaCard from "./ImgMediaCard";
import TopDescription from "./TopDescription";
import React, { useEffect, useState } from "react";
import axios from "axios";

const useStyles = makeStyles({
  root: {
    padding: "10pt",
    //    maxWidth: 345,
  },
});

function Content() {
  const [contents, setContents] = useState([]);
  useEffect(() => {
    axios.get("./asset/contents.json").then((res) => {
      setContents(res.data);
    });
  }, []);

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} lg={12} sm={12} md={12}>
        <TopDescription />
      </Grid>
      <Divider />
      <Grid container spacing={4}>
        {contents.map((content) => (
          <ImgMediaCard
            title={content.title}
            href={content.href}
            image={content.image}
            description={content.description}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default Content;
